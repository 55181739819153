import { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ICONS */
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";

/* ASSETS */
import Logo from "assets/images/profitfolio-logo.png";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const newsletterFormValidation = yup.object().shape({
  email: yup.string().email().required("Email Address is required."),
});

const Footer = () => {
  const [newsletterFormLoading, setNewsletterFormLoading] = useState(false);

  /* NEWSLETTER FORM HANDLING */
  const newsletterFormHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: newsletterFormValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setNewsletterFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
          setNewsletterFormLoading(false);
        })
        .catch((e) => {
          setNewsletterFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          newsletterFormHandling.handleReset();
          setNewsletterFormLoading(false);
        });
    },
  });

  return (
    <footer className="bg-black">
      <div className="container mx-auto">
        {/* CALL TO ACTION */}
        <div className="py-5 xl:py-8 lg:py-8">
          <div className="block xl:flex lg:flex items-center justify-between">
            <div className="w-full xl:w-1/2 lg:w-1/2">
              <h2 className="text-3xl xl:text-4xl lg:text-4xl font-tenon-bold text-white leading-tight">
                Revolutionize the way you manage, invest and grow your{" "}
                <span className="relative">
                  finances
                  <div className="absolute -bottom-1 left-0 w-full h-1 bg-logo rounded-full" />
                </span>
              </h2>
              <p className="text-lg font-proxima-regular text-white text-opacity-70 mt-5 xl:mt-3 lg:mt-3">
                Take control of your financial future today. Explore our
                innovative tools and strategies to manage, invest, and grow your
                wealth.
              </p>
            </div>
            <div className="flex items-center gap-3 mt-5 xl:mt-0 lg:mt-0">
              <Button className="w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-logo hover:bg-opacity-70 rounded-md px-5 py-2.5 text-base font-proxima-semibold normal-case transition-all duration-300 ease-in-out">
                Schedule a 1:1 call
              </Button>
            </div>
          </div>
        </div>

        {/* NAVIGATION FOOTER */}
        <div className="py-5 xl:py-8 lg:py-8 border-t border-white border-opacity-30">
          <div className="block xl:flex lg:flex items-start justify-between">
            {/* LEFT SECTION */}
            <div className="w-full xl:w-1/4 lg:w-1/4">
              <div className="w-fit bg-white p-0.5 rounded">
                <img
                  src={Logo}
                  className="h-10 w-auto object-contain"
                  alt="Profitfolio"
                />
              </div>
              <p className="text-base font-proxima-regular text-white text-opacity-70 mt-5">
                We are a B2B focused cutting-edge fintech firm committed to
                revolutionizing the way you manage, invest, and grow your
                finances.
              </p>
              <div className="mt-5">
                <p className="text-sm font-proxima-regular text-white text-opacity-70">
                  Follow us on
                </p>
                <div className="flex items-center gap-2 mt-2">
                  <Button className="shadow-none hover:shadow-none bg-white bg-opacity-20 rounded-full p-3 hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                    <FaFacebookF className="w-4 h-4 text-white text-opacity-70" />
                  </Button>
                  <Button className="shadow-none hover:shadow-none bg-white bg-opacity-20 rounded-full p-3 hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                    <FaInstagram className="w-4 h-4 text-white text-opacity-70" />
                  </Button>
                  <Button className="shadow-none hover:shadow-none bg-white bg-opacity-20 rounded-full p-3 hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                    <FaLinkedin className="w-4 h-4 text-white text-opacity-70" />
                  </Button>
                </div>
              </div>
            </div>

            {/* RIGHT SECTION */}
            <div className="w-full xl:w-3/5 lg:w-3/5 mt-10 xl:mt-0 lg:mt-0">
              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-10 lg:gap-10">
                {/* QUICK LINKS */}
                <div className="col-span-1">
                  <h5 className="text-lg font-proxima-bold text-white">
                    Quick Links
                  </h5>
                  <div className="mt-2 flex xl:block lg:block items-center gap-y-3 gap-x-5 flex-wrap">
                    <a
                      href="#our-folios"
                      className="mt-0 xl:mt-5 lg:mt-5 block text-base font-proxima-semibold text-white text-opacity-70 hover:text-logo transition-all duration-300 ease-in-out"
                    >
                      Our Folios
                    </a>
                    <a
                      href="#why-choose-us"
                      className="mt-0 xl:mt-5 lg:mt-5 block text-base font-proxima-semibold text-white text-opacity-70 hover:text-logo transition-all duration-300 ease-in-out"
                    >
                      Why choose us
                    </a>
                    <a
                      href="#who-we-cater-to"
                      className="mt-0 xl:mt-5 lg:mt-5 block text-base font-proxima-semibold text-white text-opacity-70 hover:text-logo transition-all duration-300 ease-in-out"
                    >
                      Who we cater to
                    </a>
                    <a
                      href="#our-expertise"
                      className="mt-0 xl:mt-5 lg:mt-5 block text-base font-proxima-semibold text-white text-opacity-70 hover:text-logo transition-all duration-300 ease-in-out"
                    >
                      Our Expertise
                    </a>
                    <a
                      href="#contact-us"
                      className="mt-0 xl:mt-5 lg:mt-5 block text-base font-proxima-semibold text-white text-opacity-70 hover:text-logo transition-all duration-300 ease-in-out"
                    >
                      Contact us
                    </a>
                  </div>
                </div>

                {/* NEWSLETTER FORM */}
                <div className="col-span-2">
                  <div className="bg-white w-full rounded-md shadow p-5">
                    <div className="p-0">
                      <h5 className="text-black text-lg font-proxima-bold">
                        Newsletter
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText mt-1">
                        Get the latest updates on tips about stock markets, algo
                        trading strategies in just one click in your inbox.
                      </p>
                    </div>
                    <form
                      className="mt-3 flex items-end gap-3"
                      onSubmit={newsletterFormHandling.handleSubmit}
                    >
                      <div className="form-group w-full">
                        <label
                          htmlFor="email"
                          className="text-sm font-proxima-regular text-greyText"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder="placeholder@gmail.com"
                            value={newsletterFormHandling.values.email}
                            onChange={newsletterFormHandling.handleChange}
                            className={`w-full text-sm border ${
                              newsletterFormHandling.errors.email &&
                              newsletterFormHandling.touched.email
                                ? "border-red-300"
                                : "border-greyBorder"
                            } font-proxima-regular block outline-none focus:outline-none bg-white px-4 py-2.5 rounded-md placeholder:font-proxima-regular text-black`}
                          />
                        </div>
                      </div>
                      <Button
                        type="submit"
                        className="flex-shrink-0 text-xs shadow-none hover:shadow-none bg-logo rounded-md flex items-center gap-1 px-4 normal-case py-3 hover:bg-opacity-70 font-proxima-semibold"
                      >
                        {newsletterFormLoading ? "Please wait.." : "Subscribe"}
                        <GoArrowUpRight className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* COPYRIGHT FOOTER */}
        <div className="py-4 border-t border-white border-opacity-30">
          <div className="flex items-center justify-between">
            <p className="text-sm font-proxima-regular text-white text-opacity-70">
              © 2024 Profitfolio. All rights reserved.
            </p>

            {/* POLICY DOCUMENTS */}
            <div className="flex items-center gap-10">
              <Link
                to="/risk-disclosure"
                className="block text-sm font-proxima-regular text-white text-opacity-70 hover:text-logo"
              >
                Risk Disclosure
              </Link>
              <Link
                to="/privacy-policy"
                className="block text-sm font-proxima-regular text-white text-opacity-70 hover:text-logo"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-and-conditions"
                className="block text-sm font-proxima-regular text-white text-opacity-70 hover:text-logo"
              >
                Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
