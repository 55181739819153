/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* PACKAGES */
import { AnimatePresence } from "framer-motion";

/* STYLES */
import "styles/globals.css";

/* LAYOUT */
import Layout from "layout";

/* PAGES */
import Homepage from "pages";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <AnimatePresence>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Homepage />
                </Layout>
              }
            />
          </Routes>
        </Router>
      </AnimatePresence>
    </div>
  );
};

export default AppRoutes;
