/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Link } from "react-router-dom";

/* IMAGES */
import Logo from "assets/images/profitfolio-logo.png";
import { Button, Collapse } from "@material-tailwind/react";

/* ICONS */
import { HiBars2 } from "react-icons/hi2";

/* FOLIOS */
const folioRoutes = [
  {
    id: 1,
    name: "TechFolio",
    link: "#folios",
  },
  {
    id: 2,
    name: "AlgoFolio",
    link: "#folios",
  },
  {
    id: 3,
    name: "ResearchFolio",
    link: "#folios",
  },
  {
    id: 4,
    name: "AlphaFolio",
    link: "#folios",
  },
  {
    id: 5,
    name: "EdgeFolio",
    link: "#folios",
  },
  {
    id: 6,
    name: "OptionsFolio",
    link: "/optionsfolio",
  },
];

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <header className="bg-white shadow-md py-3 relative z-50">
      <div className="container mx-auto">
        <div className="grid grid-cols-2 items-center">
          {/* LOGO */}
          <div className="col-span-1">
            <Link to="/">
              <img
                src={Logo}
                className="h-10 w-auto object-contain"
                alt="Profitfolio"
              />
            </Link>
          </div>

          {/* NAVIGATION OPTIONS */}
          <div className="col-span-1 hidden xl:flex lg:flex items-center gap-10 justify-end">
            <Link
              to="/"
              className="text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Home
            </Link>
            <a
              href="#our-folios"
              className="text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Our Folios
            </a>
            <a
              href="#why-choose-us"
              className="text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Why choose us
            </a>
            <a
              href="#who-we-cater-to"
              className="text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Who we cater to
            </a>
            <a
              href="#our-expertise"
              className="text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Our Expertise
            </a>
            <a
              href="#contact-us"
              className="text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Contact us
            </a>
          </div>

          {/* MOBILE MENU */}
          <div className="col-span-1 flex xl:hidden lg:hidden items-center justify-end">
            <Button
              className="w-fit shadow-none hover:shadow-none bg-white border border-greyBorder rounded-full p-2"
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              <HiBars2 className="w-5 h-5 text-greyText" />
            </Button>
          </div>
        </div>
      </div>

      <Collapse open={mobileMenu}>
        <div className="p-5 bg-white">
          <Link
            to="/"
            className="block text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            Home
          </Link>
          <a
            href="#our-folios"
            onClick={() => setMobileMenu(!mobileMenu)}
            className="mt-3 block text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
          >
            Our Folios
          </a>
          <a
            href="#why-choose-us"
            onClick={() => setMobileMenu(!mobileMenu)}
            className="mt-3 block text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
          >
            Why choose us
          </a>
          <a
            href="#who-we-cater-to"
            onClick={() => setMobileMenu(!mobileMenu)}
            className="mt-3 block text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
          >
            Who we cater to
          </a>
          <a
            href="#our-expertise"
            onClick={() => setMobileMenu(!mobileMenu)}
            className="mt-3 block text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
          >
            Our Expertise
          </a>
          <a
            href="#contact-us"
            onClick={() => setMobileMenu(!mobileMenu)}
            className="mt-3 block text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out"
          >
            Contact us
          </a>
        </div>
      </Collapse>
    </header>
  );
};

export default Header;
