import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import Marquee from "react-fast-marquee";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

/* COMPONENTS */
import { InputField, TextareaField } from "components/input";
import { containerAnimation, animation } from "data/animation";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Card, CardBody } from "@material-tailwind/react";

/* DATA */
import {
  assetInstruments,
  services,
  technologies,
  whoWeCaterTo,
  whyChooseUs,
} from "data/homepage";

/* ICONS */
import { GoArrowUpRight, GoArrowLeft, GoArrowRight } from "react-icons/go";
import { FaCheck } from "react-icons/fa6";

/* APIS */
import { contactUsAPI } from "service/api";

/* VALIDATION SCHEMA */
const contactUsFormValidation = yup.object().shape({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  email: yup.string().email().required("Email Address is required."),
  phoneNumber: yup.string().required("Phone Number is required."),
  message: yup.string().required("Message is required."),
});

const Homepage = () => {
  const [formLoading, setFormLoading] = useState(false);

  /* SERVICES CARD */
  const ServicesCard = (props) => {
    return (
      <Link to={props.link} className="group block">
        <Card className="bg-white border border-greyBorder border-opacity-50 rounded-lg shadow-d shadow-green-50 relative">
          <CardBody className="p-5">
            <div className="w-16 h-16 bg-logo bg-opacity-5 border border-logo border-opacity-30 rounded-md flex items-center justify-center">
              <img src={props.icon} className="w-10 h-10" alt={props.name} />
            </div>
            <div className="mt-5">
              <h5 className="text-xl font-tenon-bold text-black">
                {props.name}
              </h5>
              <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
                {props.description}
              </p>
            </div>
          </CardBody>
          <div className="absolute top-3 right-3">
            <Button className="shadow-none hover:shadow-none bg-white group-hover:bg-logo border border-greyBorder group-hover:border-logo rounded-full p-3 text-greyText group-hover:text-white transition-all duration-300 ease-in-out">
              <GoArrowUpRight className="w-5 h-5" />
            </Button>
          </div>
        </Card>
      </Link>
    );
  };

  /* TECHNOLOGY CARD */
  const TechnologyCard = (props) => {
    return (
      <Card className="group block w-[15vh] h-[15vh] bg-white shadow-lg border border-greyBorder border-opacity-70 shadow-gray-100 cursor-default">
        <CardBody className="p-5 text-center h-full flex flex-col items-center justify-center">
          <img
            src={props.img}
            className="w-[7vh] h-[7vh] object-contain mx-auto rounded"
            alt={props.name}
          />
          <div className="mt-3 text-center">
            <p className="text-base font-inter-semibold text-black">
              {props.name}
            </p>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* WHY CHOOSE US CARD FIXED */
  const WhyChooseUsCardFixed = (props) => {
    return (
      <div className="mt-5 xl:mt-0 lg:mt-0 w-full xl:w-whyChooseUs lg:w-whyChooseUs bg-white border border-greyBorder border-opacity-50 rounded-lg p-5 shadow">
        <h5 className="text-xl font-tenon-bold text-black">{props.name}</h5>
        <p className="mt-2 text-base font-proxima-regular text-greyText">
          {props.description}
        </p>
      </div>
    );
  };

  /* WHO WE CATER TO TILE */
  const CaterTile = (props) => {
    return (
      <div className="flex items-center gap-4 mt-4 first:mt-0">
        <div className="w-6 h-6 bg-logo bg-opacity-10 border border-logo border-opacity-50 rounded-full flex-shrink-0 flex items-center justify-center">
          <FaCheck className="w-3 h-3 text-logo" />
        </div>
        <h5 className="text-md font-proxima-semibold text-black">
          {props.name}
        </h5>
      </div>
    );
  };

  /* INSTRUMENTS TILE */
  const InstrumentsTile = (props) => {
    return (
      <div className="bg-transparent hover:bg-logo cursor-default border-2 border-logo shadow-xl px-5 xl:px-8 lg:px-8 py-2.5 rounded-lg transition-all duration-300 ease-in-out">
        <p className="text-base xl:text-[2.1vh] lg:text-[2.1vh] font-tenon-bold text-white text-center">
          {props.title}
        </p>
      </div>
    );
  };

  /* CONTACT US FORM HANDLING */
  const formikHandling = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: contactUsFormValidation,
    onSubmit: (values) => {
      const obj = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        message: values.message,
      };
      setFormLoading(true);
      contactUsAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formikHandling.handleReset();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
    >
      {/* BANNER */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12 bg-banner items-center z-10"
      >
        <div className="container mx-auto">
          <div className="w-full xl:w-[70%] lg:w-[70%] mx-auto relative">
            <div className="flex xl:hidden lg:hidden items-center gap-3 mb-5 xl:mb-0 lg:mb-0">
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/cryptocurrency.png")}
                  className="w-9 h-9"
                  alt="Cryptocurrency"
                />
              </div>
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/stock-market.png")}
                  className="w-9 h-9"
                  alt="Stock Market"
                />
              </div>
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/chess.png")}
                  className="w-9 h-9 mx-auto"
                  alt="Analysis"
                />
              </div>
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/nft.png")}
                  className="w-9 h-9"
                  alt="NFT"
                />
              </div>
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/forex.png")}
                  className="w-9 h-9"
                  alt="Forex"
                />
              </div>
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/automation.png")}
                  className="w-9 h-9 mx-auto"
                  alt="Automation"
                />
              </div>
            </div>

            <div className="mx-auto w-fit bg-logo rounded-full px-1.5 xl:px-4 lg:px-4 py-1.5">
              <p className="text-base font-proxima-semibold text-white text-center xl:text-left lg:text-left">
                Achieving Financial Freedom is always the Best Endeavour
              </p>
            </div>
            <h1 className="mt-5 text-5xl xl:text-7xl lg:text-7xl font-tenon-extrabold text-black text-center leading-tight">
              Make your portfolio
              <br />
              <span className="text-logo">Profitfolio</span>
            </h1>
            <h2 className="w-full xl:w-4/5 lg:w-4/5 mx-auto mt-5 xl:mt-10 lg:mt-10 text-lg font-proxima-regular text-greyText text-center leading-relaxed">
              We are a B2B focused cutting-edge fintech firm committed to
              revolutionizing the way you manage, invest, and grow your
              finances. With a relentless focus on technological advancement, we
              provide seamless solutions tailored to empower your financial
              journey. Join us as we pave the way for a smarter, more connected
              financial future.
            </h2>
            <div className="mt-5 xl:mt-10 lg:mt-10">
              <div className="flex items-center justify-center gap-5">
                <a href="#contact-us">
                  <Button className="shadow hover:shadow bg-logo border border-logo hover:bg-opacity-70 text-white font-proxima-semibold normal-case text-md transition-all duration-300 ease-in-out">
                    Contact us
                  </Button>
                </a>
                <a href="#about-us">
                  <Button className="shadow hover:shadow bg-white border border-greyBorder hover:bg-opacity-70 text-black font-proxima-semibold normal-case text-md transition-all duration-300 ease-in-out">
                    Learn more about us
                  </Button>
                </a>
              </div>
            </div>

            {/* ICONS */}
            <div className="absolute top-0 left-0 hidden xl:block lg:block">
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/cryptocurrency.png")}
                  className="w-9 h-9"
                  alt="Cryptocurrency"
                />
              </div>
            </div>

            <div className="absolute top-[12vh] -left-20 hidden xl:block lg:block">
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/stock-market.png")}
                  className="w-9 h-9"
                  alt="Stock Market"
                />
              </div>
            </div>

            <div className="absolute top-[25vh] -left-[20vh] hidden xl:block lg:block">
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/chess.png")}
                  className="w-9 h-9 mx-auto"
                  alt="Analysis"
                />
              </div>
            </div>

            <div className="absolute top-0 right-0 hidden xl:block lg:block">
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/nft.png")}
                  className="w-9 h-9"
                  alt="NFT"
                />
              </div>
            </div>

            <div className="absolute top-[12vh] -right-20 hidden xl:block lg:block">
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/forex.png")}
                  className="w-9 h-9"
                  alt="Forex"
                />
              </div>
            </div>

            <div className="absolute top-[25vh] -right-[20vh] hidden xl:block lg:block">
              <div className="w-14 h-14 bg-white rounded-full border border-greyBorder shadow-md flex items-center justify-center animate-bounce">
                <img
                  src={require("../assets/images/automation.png")}
                  className="w-9 h-9 mx-auto"
                  alt="Automation"
                />
              </div>
            </div>
          </div>

          <div className="mt-10 xl:mt-[10vh] lg:mt-[10vh]">
            <div className="grid grid-cols-2 xl:grid-cols-5 lg:grid-cols-5 gap-y-10">
              <div className="col-span-1 flex items-center justify-center">
                <div className="p-0">
                  <div className="pb-4">
                    <img
                      src={require("../assets/images/nse-logo.png")}
                      className="w-[11vh] h-[4vh] object-contain mx-auto"
                      alt="NSE"
                    />
                  </div>
                  <div className="pt-5 border-t border-logo border-opacity-30">
                    <div className="flex items-center justify-center gap-3">
                      <img
                        src={require("../assets/images/india-flag.png")}
                        className="w-7 h-7"
                        alt="India"
                      />
                      <p className="text-lg font-proxima-semibold text-black">
                        India
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <div className="p-0">
                  <div className="pb-4">
                    <img
                      src={require("../assets/images/mcxsx.png")}
                      className="w-[11vh] h-[4vh] object-contain mx-auto"
                      alt="MCX"
                    />
                  </div>
                  <div className="pt-5 border-t border-logo border-opacity-30">
                    <div className="flex items-center justify-center gap-3">
                      <img
                        src={require("../assets/images/india-flag.png")}
                        className="w-7 h-7"
                        alt="India"
                      />
                      <p className="text-lg font-proxima-semibold text-black">
                        India
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <div className="p-0">
                  <div className="pb-4">
                    <img
                      src={require("../assets/images/nasdaq.png")}
                      className="w-[11vh] h-[4vh] object-contain mx-auto"
                      alt="Nasdaq"
                    />
                  </div>
                  <div className="pt-5 border-t border-logo border-opacity-30">
                    <div className="flex items-center justify-center gap-3">
                      <img
                        src={require("../assets/images/usa-flag.png")}
                        className="w-7 h-7"
                        alt="United States"
                      />
                      <p className="text-lg font-proxima-semibold text-black">
                        United States
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <div className="p-0">
                  <div className="pb-4">
                    <img
                      src={require("../assets/images/nyse.png")}
                      className="w-[11vh] h-[4vh] object-contain mx-auto"
                      alt="NYSE"
                    />
                  </div>
                  <div className="pt-5 border-t border-logo border-opacity-30">
                    <div className="flex items-center justify-center gap-3">
                      <img
                        src={require("../assets/images/usa-flag.png")}
                        className="w-7 h-7"
                        alt="United States"
                      />
                      <p className="text-lg font-proxima-semibold text-black">
                        United States
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <div className="p-0">
                  <div className="pb-4">
                    <img
                      src={require("../assets/images/cboe.png")}
                      className="w-[11vh] h-[4vh] object-contain mx-auto"
                      alt="CBOE"
                    />
                  </div>
                  <div className="pt-5 border-t border-logo border-opacity-30">
                    <div className="flex items-center justify-center gap-3">
                      <img
                        src={require("../assets/images/usa-flag.png")}
                        className="w-7 h-7"
                        alt="United States"
                      />
                      <p className="text-lg font-proxima-semibold text-black">
                        United States
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FOLIOS */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12"
        id="our-folios"
      >
        <div className="container mx-auto">
          <div className="block xl:flex lg:flex items-center gap-10">
            {/* HEADING */}
            <div className="w-full xl:w-1/3 lg:w-1/3">
              {/* BADGE */}
              <div className="w-fit bg-logo rounded-full px-4 py-1.5">
                <p className="text-base font-proxima-semibold text-white">
                  Our services
                </p>
              </div>

              {/* HEADING */}
              <h2 className="mt-5 text-3xl xl:text-4xl lg:text-4xl font-tenon-bold text-black leading-tight">
                We call our products and services{" "}
                <span className="relative text-logo">FOLIOS</span>
              </h2>

              {/* ARROWS */}
              <div className="flex items-center gap-3 mt-5">
                <Button className="shadow-none hover:shadow-none bg-white hover:bg-logo hover:bg-opacity-10 border border-greyBorder hover:border-logo rounded-full p-3 transition-all duration-300 ease-in-out prev-btn">
                  <GoArrowLeft className="w-5 h-5 text-greyText" />
                </Button>
                <Button className="shadow-none hover:shadow-none bg-white hover:bg-logo hover:bg-opacity-10 border border-greyBorder hover:border-logo rounded-full p-3 transition-all duration-300 ease-in-out next-btn">
                  <GoArrowRight className="w-5 h-5 text-greyText" />
                </Button>
              </div>
            </div>

            {/* FOLIOS LIST */}
            <div className="w-ful xl:w-3/5 lg:w-3/5 relative mt-5 xl:mt-0 lg:mt-0">
              <Swiper
                modules={[Navigation, Autoplay, Pagination]}
                navigation={{
                  prevEl: ".prev-btn",
                  nextEl: ".next-btn",
                }}
                breakpoints={{
                  576: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                }}
                autoplay={{ delay: 3000 }}
                className="pb-1"
              >
                {services.map((data) => (
                  <SwiperSlide key={data.id}>
                    <ServicesCard
                      icon={data.icon}
                      name={data.name}
                      description={data.description}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FOLIOS MARQUEE EFFECT */}
      <motion.div variants={animation} className="py-3 xl:py-6 lg:py-6">
        <Marquee>
          <div className="flex items-center">
            <h5 className="text-7xl font-tenon-extrabold hollow-text px-5">
              TECHFOLIO
            </h5>
            <h5 className="text-2xl font-tenon-extrabold text-logo text-opacity-30">
              &#9679;
            </h5>
            <h5 className="text-7xl font-tenon-extrabold hollow-text px-5">
              ALGOFOLIO
            </h5>
            <h5 className="text-2xl font-tenon-extrabold text-logo text-opacity-30">
              &#9679;
            </h5>
            <h5 className="text-7xl font-tenon-extrabold hollow-text px-5">
              RESEARCHFOLIO
            </h5>
            <h5 className="text-2xl font-tenon-extrabold text-logo text-opacity-30">
              &#9679;
            </h5>
            <h5 className="text-7xl font-tenon-extrabold hollow-text px-5">
              ALPHAFOLIO
            </h5>
            <h5 className="text-2xl font-tenon-extrabold text-logo text-opacity-30">
              &#9679;
            </h5>
            <h5 className="text-7xl font-tenon-extrabold hollow-text px-5">
              EDGEFOLIO
            </h5>
            <h5 className="text-2xl font-tenon-extrabold text-logo text-opacity-30">
              &#9679;
            </h5>
            <h5 className="text-7xl font-tenon-extrabold hollow-text px-5">
              OPTIONSFOLIO
            </h5>
            <h5 className="text-2xl font-tenon-extrabold text-logo text-opacity-30">
              &#9679;
            </h5>
          </div>
        </Marquee>
      </motion.div>

      {/* WHY CHOOSE US */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12"
        id="why-choose-us"
      >
        <div className="container mx-auto">
          <div className="bg-greyBg bg-opacity-50 border border-greyBorder border-opacity-60 rounded-xl shadow p-5 xl:p-10 lg:p-10">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
              <div className="col-span-1">
                {/* BADGE */}
                <div className="w-fit bg-logo rounded-full px-4 py-1.5">
                  <p className="text-base font-proxima-semibold text-white">
                    Why choose us
                  </p>
                </div>

                {/* HEADING */}
                <div className="mt-5 w-full xl:w-4/5 lg:w-4/5">
                  <h2 className="text-3xl xl:text-4xl lg:text-4xl font-tenon-bold text-black leading-tight">
                    Building trust through{" "}
                    <span className="relative text-logo">
                      Transparency and Integrity
                    </span>
                  </h2>
                </div>
              </div>
              <div className="col-span-1">
                <h5 className="text-lg font-proxima-regular text-greyText leading-relaxed">
                  We care about your investments and we're here to provide
                  seamless solutions tailored to empower your financial journey.
                  Join us as we pave the way for a smarter, more connected
                  financial future.
                </h5>
              </div>
            </div>
            <div className="mt-5 xl:mt-10 lg:mt-10">
              <div className="block xl:flex lg:flex items-center justify-center gap-5">
                {whyChooseUs.slice(0, 3).map((data) => (
                  <React.Fragment key={data.id}>
                    <WhyChooseUsCardFixed
                      name={data.name}
                      description={data.description}
                    />
                  </React.Fragment>
                ))}
              </div>
              <div className="block xl:flex lg:flex items-center justify-center gap-5 mt-5">
                {whyChooseUs.slice(3, 5).map((data) => (
                  <React.Fragment key={data.id}>
                    <WhyChooseUsCardFixed
                      name={data.name}
                      description={data.description}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHO WE CATER TO */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12"
        id="who-we-cater-to"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-10 xl:gap-10 lg:gap-10">
            <div className="col-span-1">
              {/* BADGE */}
              <div className="w-fit bg-logo rounded-full px-4 py-1.5">
                <p className="text-base font-proxima-semibold text-white">
                  Who we cater to
                </p>
              </div>

              {/* HEADING */}
              <div className="mt-5 w-full xl:w-4/5 lg:w-4/5">
                <h2 className="text-3xl xl:text-4xl lg:text-4xl font-tenon-bold text-black leading-tight">
                  Tailored Financial Solutions for{" "}
                  <span className="relative text-logo">Diverse Clientele</span>
                </h2>

                <h5 className="mt-5 text-lg font-proxima-regular text-greyText leading-relaxed">
                  Serving individuals, businesses, and institutions with
                  personalized financial planning, investment strategies, and
                  advisory services.
                </h5>
              </div>

              {/* LIST */}
              <div className="mt-5 xl:mt-10 lg:mt-10">
                {whoWeCaterTo.map((data) => (
                  <React.Fragment key={data.id}>
                    <CaterTile name={data.name} />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="col-span-1">
              <div className="grid grid-cols-2 gap-5">
                <div className="col-span-1">
                  <img
                    src={require("../assets/images/investment-advisors.jpeg")}
                    className="w-full h-[20vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-xl shadow"
                    alt="Investment Advisors"
                  />
                </div>
                <div className="col-span-1">
                  <img
                    src={require("../assets/images/portfolio-manager.jpeg")}
                    className="w-full h-[20vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-xl shadow"
                    alt="Investment Advisors"
                  />
                </div>
                <div className="col-span-1">
                  <img
                    src={require("../assets/images/trading-desks.jpeg")}
                    className="w-full h-[20vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-xl shadow"
                    alt="Investment Advisors"
                  />
                </div>
                <div className="col-span-1">
                  <img
                    src={require("../assets/images/pension-funds.jpeg")}
                    className="w-full h-[20vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-xl shadow"
                    alt="Investment Advisors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ASSET CLASS AND INSTRUMENTS COVERED */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12 bg-darkBg"
        id="asset-classes"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-10 items-center">
            <div className="col-span-1">
              {/* BADGE */}
              <div className="w-fit bg-logo rounded-full px-4 py-1.5">
                <p className="text-base font-proxima-semibold text-white">
                  Assets classes and Instruments
                </p>
              </div>

              {/* HEADING */}
              <div className="mt-5 w-full xl:w-4/5 lg:w-4/5">
                <h2 className="text-3xl xl:text-4xl lg:text-4xl font-tenon-bold text-white leading-tight">
                  Navigating asset classes and{" "}
                  <span className="relative text-logo">
                    Financial instruments
                  </span>
                </h2>
              </div>
            </div>
            <div className="col-span-1">
              <h5 className="text-lg font-proxima-regular text-white text-opacity-60 leading-relaxed">
                From traditional stocks and bonds to innovative alternatives,
                discover how our diverse range of financial instruments can
                enhance your investment strategy and secure your financial
                future. Navigate the complexities of wealth management with
                confidence.
              </h5>
            </div>
          </div>

          {/* ASSETS LIST */}
          <div className="flex items-center gap-3 xl:gap-5 lg:gap-5 justify-normal xl:justify-center lg:justify-center mt-5 xl:mt-10 lg:mt-10 flex-wrap">
            {assetInstruments.map((data) => (
              <React.Fragment key={data.id}>
                <InstrumentsTile title={data.title} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </motion.div>

      {/* TECHNOLOGIES */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12"
        id="our-expertise"
      >
        <div className="container mx-auto">
          <div className="p-0">
            {/* BADGE */}
            <div className="w-fit bg-logo rounded-full px-4 py-1.5">
              <p className="text-base font-proxima-semibold text-white">
                Our Expertise
              </p>
            </div>

            {/* HEADING */}
            <div className="mt-5 w-full xl:w-4/5 lg:w-4/5">
              <h2 className="text-3xl xl:text-4xl lg:text-4xl font-tenon-bold text-black leading-tight">
                Technologies{" "}
                <span className="relative text-logo">We excel in</span>
              </h2>
            </div>
          </div>
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <Marquee>
              {technologies.map((data) => (
                <div className="px-2" key={data.id}>
                  <TechnologyCard img={data.img} name={data.name} />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </motion.div>

      {/* GET IN TOUCH */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12"
        id="contact-us"
      >
        <div className="container mx-auto">
          <div className="w-full bg-greyBg bg-opacity-50 border border-greyBorder border-opacity-50 rounded-xl p-5 xl:p-10 lg:p-10 shadow">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-20 lg:gap-20">
              {/* LOCATIONS */}
              <div className="col-span-1">
                <h3 className="text-3xl font-tenon-bold text-black">
                  Our Presence
                </h3>
                <div className="mt-0">
                  <div className="py-5 border-b border-greyBorder">
                    <h5 className="text-lg font-proxima-semibold text-black">
                      Vadodara, Gujarat, India
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-1">
                      TF-10/11, The Trillium, 24 Meter High Tension Road,
                      Vasna-Bhayli, Vadodara - 391410
                    </p>
                  </div>
                  <div className="py-5 border-b border-greyBorder">
                    <h5 className="text-lg font-proxima-semibold text-black">
                      Mumbai, Maharashtra, India
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-1">
                      F62, The Zone, Chandavarkar Road, Borivali West, Mumbai -
                      400092
                    </p>
                  </div>
                  <div className="py-5 border-b border-greyBorder">
                    <h5 className="text-lg font-proxima-semibold text-black">
                      Dubai, UAE
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-1">
                      Level 1: Office # 6 First Motors Building, 409 Sheikh
                      Zayed Rd, Al Quoz, Al Quoz 1, Dubai
                    </p>
                  </div>
                  <div className="py-5">
                    <h5 className="text-lg font-proxima-semibold text-black">
                      Rajkot, Gujarat, India
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-1">
                      Office No. 431, Level 6, Behind Big Bazaar, Rajkot, 360005
                    </p>
                  </div>
                </div>
              </div>

              {/* FORM */}
              <div className="col-span-1">
                <h3 className="text-3xl font-tenon-bold text-black">
                  Get in touch with us
                </h3>
                <form className="mt-5" onSubmit={formikHandling.handleSubmit}>
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-regular text-black">
                          First name
                        </label>
                        <div className="mt-1">
                          <InputField
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Type your first name"
                            error={
                              formikHandling.errors.firstName &&
                              formikHandling.touched.firstName
                            }
                            value={formikHandling.values.firstName}
                            onChange={formikHandling.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-regular text-black">
                          Last name
                        </label>
                        <div className="mt-1">
                          <InputField
                            type="text"
                            name="lastName"
                            id="lastName"
                            error={
                              formikHandling.errors.lastName &&
                              formikHandling.touched.lastName
                            }
                            placeholder="Type your last name"
                            value={formikHandling.values.lastName}
                            onChange={formikHandling.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-regular text-black">
                          Email address
                        </label>
                        <div className="mt-1">
                          <InputField
                            type="email"
                            name="email"
                            id="email"
                            error={
                              formikHandling.errors.email &&
                              formikHandling.touched.email
                            }
                            placeholder="Type your email address"
                            value={formikHandling.values.email}
                            onChange={formikHandling.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-regular text-black">
                          Phone number
                        </label>
                        <div className="mt-1">
                          <PhoneInput
                            name="phoneNumber"
                            id="phoneNumber"
                            defaultCountry="IN"
                            className={`${
                              formikHandling.errors.phoneNumber &&
                              formikHandling.touched.phoneNumber &&
                              "error-phone"
                            }`}
                            placeholder="Type your phone number"
                            value={formikHandling.values.phoneNumber}
                            onChange={(e) => {
                              formikHandling.setFieldValue("phoneNumber", e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="form-group">
                        <label className="text-sm font-proxima-regular text-black">
                          Message
                        </label>
                        <div className="mt-1">
                          <TextareaField
                            rows="3"
                            name="message"
                            id="message"
                            placeholder="Type your message"
                            error={
                              formikHandling.errors.message &&
                              formikHandling.touched.message
                            }
                            value={formikHandling.values.message}
                            onChange={formikHandling.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    className="mt-5 shadow-none hover:shadow-none bg-logo hover:bg-opacity-70 rounded-md text-sm font-proxima-semibold px-5 py-2.5 normal-case transition-all duration-300 ease-in-out"
                  >
                    {formLoading ? "Please wait.." : "Submit details"}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Homepage;
