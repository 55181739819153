/* LAYOUT IMPORTS */
import Header from "./header";
import Footer from "./footer";

import { ToastContainer } from "react-toastify";

const Layout = ({ children }) => {
  return (
    <>
      <ToastContainer theme="colored" closeOnClick autoClose={1250} />
      <Header />
      <main className="app">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
