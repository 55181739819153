/* ICONS */
import Techfolio from "assets/images/icons/techfolio.png";
import Algofolio from "assets/images/icons/algofolio.png";
import ResearchFolio from "assets/images/icons/researchfolio.png";
import AlphaFolio from "assets/images/icons/alphafolio.png";
import EdgeFolio from "assets/images/icons/edgefolio.png";
import OptionsFolio from "assets/images/icons/optionsfolio.png";

/* TECHNOLOGIES */
import Python from "assets/images/technologies/python.png";
import CSharp from "assets/images/technologies/c-sharp.png";
import Javascript from "assets/images/technologies/javascript.png";
import NodeJS from "assets/images/technologies/nodejs.png";
import ReactJS from "assets/images/technologies/reactjs.png";
import MetaTrader from "assets/images/technologies/meta-trader.png";
import TradingView from "assets/images/technologies/trading-view.png";
import NinjaTrader from "assets/images/technologies/ninja-trader.png";
import GoogleCloudPlatform from "assets/images/technologies/gcp.png";
import Azure from "assets/images/technologies/azure.png";
import Flutter from "assets/images/technologies/flutter.png";
import AWS from "assets/images/technologies/aws.png";

const services = [
  {
    id: 1,
    icon: Techfolio,
    name: "TechFolio",
    description:
      "We provide technological services for making and deploying OEMS, RMS, Broker Back Office, MIS Reporting and other allied assistance.",
  },
  {
    id: 2,
    icon: Algofolio,
    name: "AlgoFolio",
    description:
      "We provide development services required to deploy fully or semi-automated algorithms in simulated and live environment using all the major technology available.",
  },
  {
    id: 3,
    icon: ResearchFolio,
    name: "ResearchFolio",
    description:
      "We offer tailored and strategic research for both professional and individual investors, delivering insights to help make informed investment decisions.",
  },
  {
    id: 4,
    icon: AlphaFolio,
    name: "AlphaFolio",
    description:
      "An active investing algorithm designed to consistently out-perform the market by identifying opportunities to generate higher returns than average indices.",
  },
  {
    id: 5,
    icon: EdgeFolio,
    name: "EdgeFolio",
    description:
      "A positional trading algorithm designed to identify opportunities in the derivatives market using our research, helping traders make informed decisions.",
  },
  {
    id: 6,
    icon: OptionsFolio,
    name: "OptionsFolio",
    description:
      "A perfect blend of non-directional and directional options trading strategies in indicies, equities and commodities in US and Indian Markets.",
  },
];

const technologies = [
  {
    id: 1,
    img: Python,
    name: "Python",
  },
  {
    id: 2,
    img: CSharp,
    name: "C Sharp (C#)",
  },
  {
    id: 3,
    img: Javascript,
    name: "Javascript",
  },
  {
    id: 4,
    img: NodeJS,
    name: "Node JS",
  },
  {
    id: 5,
    img: ReactJS,
    name: "React JS",
  },
  {
    id: 6,
    img: MetaTrader,
    name: "Meta Trader",
  },
  {
    id: 7,
    img: TradingView,
    name: "Trading View",
  },
  {
    id: 8,
    img: NinjaTrader,
    name: "Ninja Trader",
  },
  {
    id: 9,
    img: GoogleCloudPlatform,
    name: "Google Cloud",
  },
  {
    id: 10,
    img: Azure,
    name: "Azure",
  },
  {
    id: 11,
    img: AWS,
    name: "Amazon Cloud",
  },
  {
    id: 12,
    img: Flutter,
    name: "Flutter",
  },
];

const whyChooseUs = [
  {
    id: 1,
    name: "FinTech Experts",
    description:
      "Innovating financial technology solutions for seamless transactions, optimized investments, and secure digital financial ecosystems. Empowering financial futures.",
  },
  {
    id: 2,
    name: "Experienced Team",
    description:
      "Seasoned professionals dedicated to crafting innovative financial solutions, driving growth, and delivering exceptional client experiences.",
  },
  {
    id: 3,
    name: "Integrity",
    description:
      "Our unwavering commitment to honesty, transparency, and ethical practices in every aspect of our business and technical development and operations.",
  },
  {
    id: 4,
    name: "Tech Savvy",
    description:
      "Harnessing cutting-edge technologies to revolutionize financial solutions, enhance user experiences, and drive innovation in finance.",
  },
  {
    id: 5,
    name: "Automation First Approach",
    description:
      "Pioneering efficiency and reliability through automated solutions, optimizing processes for seamless operations and enhanced performance..",
  },
];

const whoWeCaterTo = [
  {
    id: 1,
    name: "Hedge Funds / Alternative Investment Funds",
  },
  {
    id: 2,
    name: "Portfolio Managers",
  },
  {
    id: 3,
    name: "Brokers / Sub Brokers / Authorised Persons",
  },
  {
    id: 4,
    name: "Investment Advisors",
  },
  {
    id: 5,
    name: "Prop. Trading Desks",
  },
  {
    id: 6,
    name: "Family Offices",
  },
  {
    id: 7,
    name: "Pension Funds",
  },
];

const assetInstruments = [
  {
    id: 1,
    title: "Equity",
  },
  {
    id: 2,
    title: "Derivatives",
  },
  {
    id: 3,
    title: "Indices",
  },
  {
    id: 4,
    title: "Mutual Funds",
  },
  {
    id: 5,
    title: "ETFs",
  },
  {
    id: 6,
    title: "Commodities",
  },
  {
    id: 7,
    title: "Forex",
  },
  {
    id: 8,
    title: "Cryptocurrency",
  },
];

export { services, technologies, whyChooseUs, whoWeCaterTo, assetInstruments };
