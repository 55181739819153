/* REACT IMPORTS */
import React from "react";
import ReactDOM from "react-dom/client";

/* MATERIAL TAILWIND COMPONENTS */
import { ThemeProvider } from "@material-tailwind/react";

/* ROUTES */
import AppRoutes from "routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AppRoutes />
    </ThemeProvider>
  </React.StrictMode>
);
