const InputField = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      className={`block w-full outline-none focus:outline-none bg-white px-5 py-2.5 border ${
        props.error ? "border-red-300" : "border-greyBorder"
      } rounded-lg text-sm font-proxima-semibold placeholder:font-proxima-regular`}
    />
  );
};

const TextareaField = (props) => {
  return (
    <textarea
      rows={props.rows}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      className={`block w-full outline-none focus:outline-none bg-white px-5 py-2.5 border ${
        props.error ? "border-red-300" : "border-greyBorder"
      } rounded-lg text-sm font-proxima-semibold placeholder:font-proxima-regular`}
    />
  );
};

export { InputField, TextareaField };
