import request from "./request";

/* CONTACT US API */
export const contactUsAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/contact-us`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* NEWSLETTER API */
export const newsletterAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/news-letter`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
